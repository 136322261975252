import schttp from 'public/src/services/schttp'

export default {
  methods: {
    async getBuyBoxPrices (goods) {
      const products = goods
        .filter(item => item.buyBoxCount?.count)
        .map(item => ({
          goods_id: item.goods_id,
          goods_sn: item.goods_sn,
          mall_code: item.mall_code,
          group_id: item.buyBoxCount.group_id,
        }))
      
      if (!products.length) return
      const data = { products }
      this.$route?.query?.isDebugger && (data.isDebugger = true)
      const { list } = await schttp({
        method: 'POST',
        url: '/api/productList/buyBoxPrice/get',
        data: { products }
      })
      list.forEach(item => {
        const curGoods = this.goods.find(obj => obj.goods_id === item.goods_id)
        if (!curGoods) return
        Vue.set(curGoods, 'buyBoxPrice', item.buyBoxPrice)
      })
    }
  },
}
