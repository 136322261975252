export function runDelay(func, timeout = 1500) {
  if (typeof window === 'undefined') {
    return
  }

  if (window.requestIdleCallback) {
    window.requestIdleCallback(func, {
      timeout,
    })
  } else {
    setTimeout(func, timeout)
  }
}
