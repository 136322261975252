export function handleTrackFetchTime(_traceId) {
  const innerHTML = _traceId
  if (!innerHTML) return
  setTimeout(() => {
    const divEl = document.createElement('div')
    divEl.style.position = 'fixed'
    divEl.style.top = '2%'
    divEl.style.left = '2%'
    divEl.style.backgroundColor = 'rgba(0,0,0,0.5)'
    divEl.style.color = '#fff'
    divEl.style.padding = '10px'
    divEl.style.zIndex = '9999'
    divEl.innerHTML = innerHTML
    document.body.appendChild(divEl)
    setTimeout(() => {
      document.body.removeChild(divEl)
    }, 3000)
  }, 1500)
}

