import { CLICK_PRODUCT_CARD_IN_GOODS_DETAIL } from './eventNameConstant'

/**
 * 注册商详页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const onClickProductCardInGoodsDetail = (eventCb) => {
  eventBus.on(CLICK_PRODUCT_CARD_IN_GOODS_DETAIL, (args) => eventCb(args))
}

export {
  onClickProductCardInGoodsDetail
}
