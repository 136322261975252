import { CLICK_PRODUCT_CARD_IN_GOODS_DETAIL } from './eventNameConstant'

/**
 * 触发商详页点击商卡事件
 */
const offClickProductCardInGoodsDetail = (eventCb) => {
  eventBus.off(CLICK_PRODUCT_CARD_IN_GOODS_DETAIL, eventCb)
}

export {
  offClickProductCardInGoodsDetail
}