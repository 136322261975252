import { storeModuleName } from 'public/src/pages/product_app/store/modules/product_list_v2/index.js'
import { getOneSpaSsrContextForSSR } from 'public/src/pre_requests/modules/productList/spaSsrHydration'

export default {
  async beforeCreate() {
    const { contextForSSR, el } = getOneSpaSsrContextForSSR()
    if (contextForSSR) {
      this.$store.state[storeModuleName].context = contextForSSR
      this._spa_ssr_el = el
    }
  },

  beforeMount() {
    if (this._spa_ssr_el) {
      this.$el = this._spa_ssr_el
    }
  },
  mounted() {
    if (window?._gb_app_?.$store?.state?.rootStatus?.exchangingSpaSceneInApp) {
      window._gb_app_.$store.state.rootStatus.exchangingSpaSceneInApp = false // 显示页面的app
    }
  },
  beforeRouteLeave(to, from, next) {
    if (window?._gb_app_?.$store?.state?.rootStatus?.exchangingSpaSceneInApp) {
      window._gb_app_.$store.state.rootStatus.exchangingSpaSceneInApp = false // 显示页面的app
    }
    next()
  }
}
