import { transformImg } from '@shein/common-function'
import * as everythings from '../../goods_detail/utils/common'

const localFuncs = {
  ...everythings,
  transformImg,
  nextIdle() {
    return new Promise(resolve => {
      requestIdleCallback(() => resolve())
    })
  }
}

export default {
  provide() {
    return {
      localFuncs,
    }
  }
}
