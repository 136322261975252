import schttp from 'public/src/services/schttp'

export default async function checkOneClickPayQualification(data) {
  try {
    const res = await schttp({
      url: '/api/ocp/productListCheckQualificationForNoGoods/get',
      method: 'POST',
      data,
    })
    return Promise.resolve(res?.info || {})
  } catch(e) {
    return Promise.resolve({})
  }
}
