<template>
  <div
    v-if="COMPONENT_MAP[type]"
    class="product-list-top-background"
    :style="style"
  >
    <component :is="COMPONENT_MAP[type]" />
  </div>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  onUnmounted,
  onActivated,
  onDeactivated,
  getCurrentInstance,
  reactive,
  ref
} from 'vue'
import TabPicNav from './components/TabPicNav.vue'
import SearchTrendCard from './components/SearchTrendCard.vue'
const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  changeHeaderBg: {
    type: Boolean,
    default: false
  },
  scrollTransparent: {
    type: Boolean,
    default: false
  }
})

const style = reactive({
  opacity: 1
})

const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) return console.warn('useStore must be used after setup')
  return vm?.proxy?.$store
}
const { commit } = useStore()

const COMPONENT_MAP = {
  TabPicNav,
  SearchTrendCard
}

const inputEl = ref(null)

// 自定义搜索框背景色
const changeHeaderInputCSSVar = (flag) => {
  if (!inputEl.value) {
    inputEl.value = document.querySelector('.legacy-header .active-search-box')
    if (!inputEl.value) return
  }
  if (flag) {
    inputEl.value.style.setProperty('--search-box-bg-color', 'rgba(255, 255, 255, 0.8)')
  } else {
    inputEl.value.style.removeProperty('--search-box-bg-color')
  }
}

const setOpacity = (positionY) => {
  if (positionY > 44) {
    style.opacity = 0
  } else {
    style.opacity = 1
  }
}

const setHeadBg = (positionY) => {
  
  // 打开dialog（快加车） 会将body设置为fixed 届时scrollY会失效
  const bodyElement = document.body
  const isFixed = bodyElement.style.position === 'fixed'
  // 控制顶部背景色
  if (positionY > 10 || isFixed) {
    commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: false
      }
    })
    changeHeaderInputCSSVar(false)
  } else {
    commit('changeHomeImmersiveInfo', {
      commonHeaderBg: {
        showBlock: true,
        topTextColor: '#222',
        backgroundColor: 'rgba(255, 255, 255, 0)',
      }
    })
    changeHeaderInputCSSVar(false)
  }
}
const handleScroll = () => {
  const positionY = window.scrollY
  props.changeHeaderBg && setHeadBg(positionY)
  props.scrollTransparent && setOpacity(positionY)
}

onActivated(() => {
  handleScroll()
})
onDeactivated(() => {
  commit('changeHomeImmersiveInfo', {
    commonHeaderBg: {
      showBlock: false,
      backgroundColor: '#FFFFFF'
    }
  })
  changeHeaderInputCSSVar(false)
})
onMounted(() => {
  handleScroll()
  window.addEventListener('scroll', handleScroll, true)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll, true)
  commit('changeHomeImmersiveInfo', {
    commonHeaderBg: {
      showBlock: false,
      backgroundColor: '#FFFFFF'
    }
  })
  changeHeaderInputCSSVar(false)
})
</script>
<script>
export default {
  name: 'TopBackground'
}
</script>

<style lang="less">
.product-list-top-background {
  position: absolute;
  top: -1.17rem;
  width: 100%;
  transition: opacity 0.3s;
}
</style>
