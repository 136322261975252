/**
 * @file subscriberPage.js
 * @description 商品列表页订阅事件 请都放在这里, 在低端机中，请求接口期间js是空闲的，所以将这个事件放在此时执行
 * */ 
import { daEventCenter } from 'public/src/services/eventCenter/index'

let isAdd = false
export default function addSubscriberPage() {
  if (isAdd) return
  isAdd = true
  daEventCenter.addSubscriber({ modulecode: '2-3' }) // 商品列表
  daEventCenter.addSubscriber({ modulecode: '2-17' }) // filter
  daEventCenter.addSubscriber({ modulecode: '1-5-1' }) // 列表
  daEventCenter.addSubscriber({ modulecode: '1-36-1' })
}
