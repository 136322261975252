<template>
  <div
    id="product-list-v2"
    class="product-list-v2 product-list-v2_new"
    :class="skeletonLoadingLockClass"
    :da-expose-code="PageState.exposeCode"
  >
    <template v-if="PageState.ready">
      <TopBackground
        v-if="!!topBackgroundParam"
        :type="topBackgroundParam.name"
        :change-header-bg="topBackgroundParam.changeHeaderBg"
        :scroll-transparent="topBackgroundParam.scrollTransparent"
      />
      <SearchShopBanner
        v-if="searchStoreCard"
        :style="{
          paddingBottom: Array.isArray(picTopNav) && picTopNav.length > 1 ? '0' : ''
        }"
        :shop-data="searchStoreCard"
        :language="language"
        :locals="locals"
        :shein-club-info="Results.sheinClubInfo"
        :expose-code="PageState.exposeCode"
      />
      <SearchTrendCard
        v-if="searchTrendCard"
        :trend-data="searchTrendCard"
        :language="language"
        :shein-club-info="Results.sheinClubInfo"
        :expose-code="PageState.exposeCode"
        :constant-data="constantData"
      />
      <SearchCCCCard
        v-if="ComponentState.searchCCCCard"
        ref="searchCCCCard"
        :locals="locals"
        :ccc-card="ComponentState.searchCCCCard"
        @subscribeExpose="onSubscribeExpose"
      />
      <BrandBanner
        v-else-if="ComponentState.BrandBanner"
        ref="brandBanner"
        :locals="locals"
        :brand-data="ComponentState.BrandBanner"
      />
      <!-- 背景配置 -->
      <BgBanner
        v-else-if="ComponentState.BgBanner"
        ref="bgBanner"
        :locals="locals"
        :config="ComponentState.BgBanner"
      />
      <ListTopInfo
        v-if="ComponentState.ListTopInfo && abtNoResultPage"
        ref="ListTopInfo"
        :search-suggest-new="abtNoResultPage"
        :interception="interception"
        :is-suggest-correction="isSuggestCorrection && goods.length !== 0 "
        :has-correction-word="hasCorrectionWord"
      />
      <template v-if="!isNewFilterBar">
        <FilterBar
          v-show="filterBarDisPlay"
          ref="FilterBar"
          :show="filterBarDisPlay"
          :cur-page-refresh="Request.type === 'refresh'"
          :interception="interception"
          :is-show-bg-banner="Boolean(ComponentState.BgBanner)"
          :banner-tag="bannerTag"
          :split-goods-render="false"
          @saveCurrAttr="handleSaveCurrAttr"
          @filterChange="updateQuery"
          @picTopNavSmallChange="updatePolicyBannerScrollTop(true)"
          @fixedChange="updatePolicyBannerScrollTop"
        />

        <!-- 福利政策 -->
        <PolicyBanner
          v-if="!(bannerTag && bannerTag.showTag) && policyBannerConfig.content.length"
          ref="refPolicyBanner"
          :cat-info="catInfo"
          :show="filterBarDisPlay"
          :policy-banner-config="policyBannerConfig"
        />
      </template>
      <template v-else>
        <!-- 图文 -->
        <PicTopNav
          v-show="filterBarDisPlay && isShowPicTopNav"
          ref="refPicTopNav"
          :locals="locals"
          :list="picTopNav"
          :filter-bar="filterBar"
          :query-params="queryParams"
          :language="language"
          :cat-info="catInfo"
          :list-abt-result="listAbtResult"
          :loading="isReflesh"
          :constant-data="constantData"
          :has-search-banner-card="hasSearchBannerCard"
          :tab-bg-color="ptnTabBgColor"
          :has-correction-word="hasCorrectionWord"
          @change="updateQuery" 
        />
        <!-- 排序栏 -->
        <NavBar 
          v-show="filterBarDisPlay"
          ref="refNavBar"
          :locals="locals"
          :abt-result="listAbtResult"
          :list-abt-result="listAbtResult"
          :filter-bar="filterBar"
          :query-params="queryParams"
          :language="language"
          :cat-info="catInfo"
          :sum="sum"
          :pic-top-nav="picTopNav"
          :sort-map="sortMap"
          :sort-map2="sortMap2"
          :sort-all-list="sortAllList"
          :loading="isReflesh"
          :close-bottom-left="closeBottomLeft"
          :fh-context="fhContext"
          :constant-data="constantData"
          :google-context="googleContext"
          @change="updateQuery"
          @saveCurrAttr="saveCurrAttr"
        />

        <!-- 标签 -->
        <CloudTags
          v-show="filterBarDisPlay"
          ref="refCloudTags"
          :locals="locals"
          :list-abt-result="listAbtResult"
          :filter-bar="filterBar"
          :query-params="queryParams"
          :language="language"
          :pic-top-nav="picTopNav"
          :cat-info="catInfo"
          :sum="sum"
          :close-bottom-left="closeBottomLeft"
          :loading="isReflesh"
          :fh-context="fhContext"
          :banner-tag="bannerTag"
          :google-context="googleContext"
          :constant-data="constantData"
          :is-show-kid-tag-popover="isShowKidTagPopover"
          @change="onCloudTagsChange"
          @toStickyByClick="toStickyByClick"
          @saveCurrAttr="saveCurrAttr"
        />

        <!-- 一键购标签 -->
        <OneClickPayTag 
          v-show="filterBarDisPlay"
          ref="refOneClickPayTag"
          :cat-info="catInfo"
          :loading="isReflesh"
          :filter-bar="filterBar"
          :banner-tag="bannerTag"
          :constant-data="constantData"
          :query-params="queryParams"
          :language="language"
          :fh-context="fhContext"
          :google-context="googleContext"
          @change="updateQuery"
        />
        <!-- 政策条 -->
        <PolicyBanner2
          v-if="filterBarDisPlay && policyBannerConfig.content.length"
          ref="refPolicyBanner"
          :cat-info="catInfo"
          :filter-bar="filterBar"
          :policy-banner-config="policyBannerConfig"
        />
      </template>

      <!-- 搜索置顶券 - 挂载点 -->
      <div
        id="j-coupon-banner-top"
      >
      </div>
      <ListTopInfo
        v-if="ComponentState.ListTopInfo && !abtNoResultPage"
        ref="ListTopInfo"
        :interception="interception"
        :is-suggest-correction="isSuggestCorrection && goods.length !== 0 "
      />
      <!-- 列表 -->
      <div
        v-show="goods.length"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="scrollDisabled"
        infinite-scroll-nodata="noMoreSearchData"
        infinite-scroll-window="true"
        :infinite-scroll-distance="infiniteScrollDistance"
      >
        <ProductList
          ref="ProductList"
          :interception="interception"
          :no-more-data="noMoreSearchData"
          :landing="PageState.lcp"
          :cur-page-refresh="Request.type === 'refresh'"
          :search-feedback-visibility="!!(goods.length > 0 && ComponentState.SearchFeedback)"
          :show-skeleton="PageState.searchPageLoading || PageState.skeletonLoading"
          :report-metrics="reportMetrics"
          :showFashionStore="showFashionStore"
          :add-recommend-product-handle="addRecommendProductHandle"
          :local-index="localIndex"
          @clickGoods="handleClickGoods"
          @doFilt="PageState"
          @operateInRecPopup="handleOperateInRecPopup"
          @oneClickPayComplete="handleOneClickPayComplete"
          @quickAddSuccess="handleQuickAddSuccess"
          @clickQuickAddBtn="fetchNewestData"
          @clickStartAddQuick="clickStartAddQuick"
        />
        <div 
          v-if="showStoreRecommend && noMoreSearchData && !Request.type"
          class="no_more"
        >
          <i class="wrap"></i>
          <span> {{ language.SHEIN_KEY_PWA_27215 }} </span>
          <i class="wrap"></i>
        </div>
      </div>
      <ClientOnly>
        <!-- 上位词搜索到的商品列表 -->
        <HypernymProductWrap
          v-if="hypernymProductInfo.list.length && sum <= 200"
          ref="HypernymProductWrap"
          :info="hypernymProductInfo"
          :search-goods-sum="goods.length"
          @loadMoreHypernymGoods="loadMoreHypernymGoods"
          @hypernymProductMounted="initHypernymListExpose"
          @dropCart="dropCart"
          @oneClickPayComplete="handleOneClickPayComplete"
        />
      </ClientOnly>
      <!-- 参见wiki:pageId=1274994420 -->
      <template v-if="noResultShow && isNewStoreNoResult">
        <!-- 给店铺搜索结果列表的 搜索无结果组件 当命中对应需求的实验且店铺搜索时，未命中实验店铺搜索结果还是走下面的NoResult -->  
        <!-- 建议以后的店铺搜索空态可以在这里扩展 -->
        <StoreNoResult
          class="fsp-element"
          :keyword="searchKeywords.keywords"
        />
      </template>
      <template v-else-if="noResultShow">
        <NoResult
          v-if="noResultShow"
          class="fsp-element"
          :has-rec-content="hasRecContent"
          :config="ComponentState.NoResult"
          :keyword="searchKeywords.keywords"
          :search-related-state="ComponentState.SearchRelated"
          :search-feedback-state="ComponentState.SearchFeedback"
          @searchInAll="searchInAll"
          @reselect="reselect"
        />
      </template>
    </template>
    <ClientOnly>
      <!-- 搜索跳转进来第一次需要显示page loading -->
      <template v-if="!PageState.searchPageLoading">
        <ListSkeletonV2
          v-if="listSkeletonShow"
          page="productList"
        />
        <ListLoaders
          v-else
          :pageleave="PageState.pageleave || hypernymProductInfo.loading"
          :request-type="Request.type || (hypernymProductInfo.loading ? 'nextpage' : '')"
        />
      </template>

      <SearchFeedback
        v-if="goods.length > 0 && ComponentState.SearchFeedback"
        ref="SearchFeedback"
        :float-type="true"
        :locals="locals"
        :state="ComponentState.SearchFeedback"
        :list-abt-result="listAbtResult"
      />
      <!-- 店铺搜索内容小于等于4个时,展示推荐 -->
      <!-- 使用v-show可以让StoreRecommend组件内提前请求数据 -->
      <StoreRecommend 
        v-if="showStoreRecommend"
        ref="StoreRecommend"
        :store-code="storeCode"
        page-name="page_store_search"
        :productItemConfig="{
          itemDAEventExposeId: '1-63-3-2',
          itemDAEventClickId: '1-63-3-3',
          itemDAEventCarId: '1-63-3-4',
          useOwnClickAddBagAnalysis: true,
        }"
        :is-three-column-num="isThreeColumnNum"
      >
        <template #gap>
          <!-- 无搜索内容时,需要与上方有一点小间隔 -->
          <div 
            v-if="!goods.length" 
            class="top_gap" 
          ></div>
        </template>
      </StoreRecommend>
      <template v-if="noMoreSearchData && ComponentState.ListBottomInfo">
        <ListRecommend
          v-if="hypernymProductInfo.noMoreData"
          :interception="interception" 
          @hasRecGoods="hasRecContent = true"
        />
        <!-- back to top 要求有推荐位就要展示 -->
        <ListBottomInfo
          v-if="goods.length > 8 || hasRecContent"
          :context="ComponentState.ListBottomInfo"
          @subscribeExpose="onSubscribeExpose"
        />
      </template>
    </ClientOnly>
    <!-- 搜索吸底券 - 挂载点 -->
    <div id="j-coupon-banner-bottom">
    </div>
  </div>
</template>

<script>
// Vue
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { storeModuleName } from '../product_app/store/modules/product_list_v2'
import { getSearchDiff } from 'public/src/pages/product_app/store/modules/product_list_v2/utils'
import ClientOnly from 'vue-client-only'

// components
import ListTopInfo from './components/ListTopInfo.vue'
// import FilterBar from './components/FilterBar.vue'
import ProductList from './components/ProductList.vue'
import ListSkeletonV2 from './components/ListSkeletonV2/index.vue'
import SearchCCCCard from 'public/src/pages/product_list_v2/components/SearchCCCCard.vue'
import SearchTrendCard from 'public/src/pages/product_list_v2/components/SearchTrendCard.vue'
import SearchShopBanner from 'public/src/pages/components/ccc/components/shop-banner/SearchShopBanner.vue'
import TopBackground from 'public/src/pages/product_list_v2/components/TopBackground/Index.vue'

// mixin
import storeMixins from './js/storeMixins'
import provideMixins from './js/provideMixins'
import routeMixins from './js/routeMixins'
import spaPreloadHtmlMixins from './js/spaPreloadHtmlMixins'
import buyBoxPriceMixin from '@/public/src/pages/product_list_v2/js/buyBoxPriceMixin.js'
import filterBarMixins from '@/public/src/pages/components/FilterBar/mixin/filterBarMixins'
import dynamicRecommendProductMixin from '@/public/src/pages/product_list_v2/js/dynamicRecommendProductMixin.js' // 泛列表点后推mixins

// abt & functional & analysis
import BffAdapter from './js/bffAdapter'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { markPoint, customMarkPoint } from 'public/src/services/mark/index.js'
import { searchWordsGlobalAnalysis } from 'public/src/pages/common/biz_helper/gb_sw_common'
import { ListAnalytics } from './analytics'
import { exposeSearchFilterAna } from 'public/src/pages/components/product/item_v2/analysis'
import { setFeedbackRecInfo, getFeedbackRecInSession, isResetRequestType, isShein } from './js/utils'
import { abortRequest, abortionInstance, requestProductListApi, getProductListApiCache } from 'public/src/pre_requests/modules/productList/index.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { spaJump } from 'public/src/pages/common/utils/index.js'
import addSubscriberPage from './analytics/subscriberPage'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'
import { handleGoodsDetailTrigger } from 'public/src/pre_requests/modules/goods_detail.js'
import checkOneClickPayQualification from './js/checkOneClickPayQualification'
import { getQueryString } from '@shein/common-function'

import { getCartNumBySkc } from '@/public/src/pages/product_app/util/getCartNum.js'
import { onClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOn'
import { offClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventOff'
import { handleTrackFetchTime } from './js/handleTrack'
// import { scrollAction } from './composable/useScrollAction'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import { throttle } from 'lodash'
let scrollTimeout = null

let pageFrom = '' // 页面来源
let exposeGoodsMaxIdx = 0 // 搜索页翻页优化-真实曝光的商品最大的索引，用于记录viewed深度
const pageLoadingStyle = {
  position: 'fixed',
  top: 0,
  'z-index': 1000,
  transform: 'translateZ(1000px)',
  height: '100vh',
  width: '100vw',
  background: '#fff'
}
export default {
  name: 'ProductListV2Container',
  components: {
    ClientOnly,
    ListTopInfo,
    ListSkeletonV2,
    SearchCCCCard,
    SearchTrendCard,
    SearchShopBanner,
    TopBackground,
    ProductList: storeMixins(ProductList, {
      ...mapState(storeModuleName, ['ComponentState', 'oneClickPayGuideDialogShow']),
      ...mapGetters(storeModuleName, [
        'locals', 'goods', 'appendGoods', 'catInfo', 'language', 'needShowRecTip',
        'searchKeywords', 'listAbtResult', 'cccConfig', 'flowMap', 'sheinClubInfo'
      ]),
    }),
    NoResult: async () => storeMixins((await import(/* webpackChunkName: "plv2_NoResult" */'./components/NoResult.vue')).default, {
      ...mapState(storeModuleName, [ 'PageState']),
      ...mapGetters(storeModuleName, ['locals', 'language', 'listAbtResult', 'catInfo']),
    }),
    StoreRecommend: async () => storeMixins((await import(/* webpackChunkName: "plv2_StoreRecommend" */'public/src/pages/store_pages/components/StoreRecommend.vue')).default, {
      ...mapState(storeModuleName, ['ComponentState']),
    }),
    // 考虑到以后再此上进行扩展，所以像NoResult一样把一些信息给注入进入了
    StoreNoResult: async () => storeMixins((await import(/* webpackChunkName: "plv2_StoreNoResult" */'./components/StoreNoResult.vue')).default, {
      ...mapState(storeModuleName, [ 'PageState']),
      ...mapGetters(storeModuleName, ['locals', 'language', 'listAbtResult', 'catInfo']),
    }),
    HypernymProductWrap: () => import(/* webpackChunkName: "plv2_HypernymGoods" */ './components/HypernymProductWrap.vue'),
    ListRecommend: () => import(/* webpackChunkName: "plv2_ListRecommend" */'./components/ListRecommend.vue'),
    SearchFeedback: () => import(/* webpackChunkName: "plv2_SearchFeedback" */'./components/SearchFeedback.vue'),
    ListLoaders: () => import(/* webpackChunkName: "plv2_ListLoaders" */'./components/ListLoaders.vue'),
    ListBottomInfo: () => import(/* webpackChunkName: "plv2_ListBottomInfo" */'./components/ListBottomInfo.vue'),
    BrandBanner: () => import(/* webpackChunkName: "plv2_ListBottomInfo" */'./components/BrandBanner.vue'),
    BgBanner: () => import(/* webpackChunkName: "plv2_ListBottomInfo" */'./components/BgBanner.vue'),
    // listCoupon: () => import(/* webpackChunkName: "plv2_SearchCoupon" */ './components/common/Coupon/Index.vue'),
    PolicyBanner: () => import(/* webpackChunkName: "plv2_policyBanner" */ './components/PolicyBanner.vue'),
  },
  mixins: [spaPreloadHtmlMixins, provideMixins, routeMixins, buyBoxPriceMixin, filterBarMixins({ storeModuleName }), dynamicRecommendProductMixin],
  provide() {
    const { cutImg } = this
    return {
      cutImg,
      isProductPage: true,
      getIsSupportCropImage: this.getIsSupportCropImage,
    }
  },
  asyncData({ store, context, from = {} }) {
    const isSsrPage = !from.name
    if (isSsrPage) {
      if (context?.cat_info?.type) {
        if (context.cat_info.type !== 'store') {
          store.state[storeModuleName].context = context
        }
      }
    }
  },
  data() {
    this.isUseSkeletonLoading = isShein() // 是否启用骨架屏loading
    return {
      Request: {
        fullpath: '',
        type: '',
        query: {
          page: 1,
          limit: 10,
          goodsMaxViewedIdx: 0 // 搜索页翻页优化-截断最大曝光索引
        },
      },
      scrolling: false, // 搜索页翻页优化-监听滚动
      ListAnalytics: null,
      hasRecContent: false,
      rightFilterFalg: false,
      isOperateInRecPopup: false,
      hypernymProductInfo: {
        // 上位词查询商品Info
        list: [],
        page: 1,
        limit: 20,
        sum: null,
        keywords: '',
        loading: false,
        noMoreData: false,
        request_ext: {}
      },
      hasPreFetchedGoodsDetail: false,
      recommendListPageNum: null,
      goodsLoading: false,
      reportMetrics: { img: true, addBag: true, jumpDetail: true, clickCartBag: true },
      onLineListened: false,
      isCatFilter: false, //是否是类目筛选
      searchRequestStatus: '',
      searchRecRequestStatus: '',
    }
  },
  computed: {
    ...mapState(['vuex_module_registing', 'rootSLoading', 'cartInfo', 'searchFilterWords']),
    ...mapState(storeModuleName, ['PageState', 'ComponentState', 'Results']),
    ...mapGetters(storeModuleName, [
      'locals',
      'language',
      'listAbtResult',
      'cccSeo',
      'catInfo',
      'currentCat',
      'parentCats',
      'searchKeywords',
      'sum',
      'goods',
      'dailyDates',
      'tracking',
      'request_ext',
      'goodsCrowId',
      'fhContext',
      'googleContext',
      'searchStoreCard',
      'searchTrendCard',
      'listFilterAbt',
      'policyBanner',
      'bannerTag',
      'sheinClubInfo',
      'hasSearchBannerCard',
      'topBackgroundParam',
      'ptnTabBgColor',
      'flowMap',
      'hasCorrectionWord'
    ]),
    noMoreSearchData() {
      return this.PageState?.noMoreData || false
    },
    noResultShow() {
      const mainGoodsNot = !this.goods.length && this.ComponentState.NoResult
      const hypernymProductSum = this.hypernymProductInfo.sum && this.hypernymProductInfo.list.length
      return mainGoodsNot && (this.catInfo.type === 'search' && !['store', 'brand'].includes(this.catInfo.search_type) ? hypernymProductSum === 0 : true)
    },
    storeCode() {
      return this.$route.query?.store_code
    },
    showStoreRecommend(){
      const { noResultShow, goods = [] } = this
      const { store_code, search_type } = this.$route.query // 店铺查询
      if (search_type == 'store' && store_code) {
        // abt命中了该功能,是店铺内搜索,数据搜索小于等于4个时展示推荐
        return noResultShow || (goods.length > 0 && goods.length < 5)
      }
      return false
    },
    // 店铺搜索底部是否展示三列的样式
    isThreeColumnNum() {
      const { store_code, search_type } = this.$route.query // 店铺查询
      let abtVal = this.listAbtResult?.storesearchefficiencyoptimize?.p?.storesearchefficiencyoptimize || 'old'
      // 店铺，且命中对应的实验 且 shein
      if (search_type == 'store' && store_code && abtVal === 'new2' && !this.locals?.IS_RW) {
        return true
      } 
      return false
    },
    // 是否展示 新店铺搜索结果页 顶部空态样式
    isNewStoreNoResult() {
      const { store_code, search_type } = this.$route.query // 店铺查询
      let abtVal = this.listAbtResult?.storesearchefficiencyoptimize?.p?.storesearchefficiencyoptimize || 'old'
      // 店铺，且命中对应的实验 且 shein
      if (search_type == 'store' && store_code && ['new1', 'new2'].includes(abtVal) && !this.locals?.IS_RW) {
        return true
      } 
      return false
    },
    listSkeletonShow() {
      // 列表骨架屏展示逻辑
      // 1.页面未准备好
      // 2.skeletonLoading 为 true
      // 3.非搜索结果页
      const { path = '', query = {} } = this.$route
      const isSearchResultPage = path.includes('/pdsearch/') && !query.store_code // 搜索结果页并且不是店铺搜索结果页
      return (
        !this.PageState.ready 
        ||
        this.PageState.skeletonLoading 
      ) && this.isUseSkeletonLoading
      && !isSearchResultPage
    },
    // infinite-scroll-disabled 只接受一级变量
    scrollDisabled() {
      return this.PageState.landingLock || this.Request.type || this.PageState.infiniteScrollLock || this.PageState.searchOptimizeReqFlag
    },
    interception() {
      return this.PageState.pageleave || this.vuex_module_registing
    },
    // 是否为店铺搜索结果
    isStoreSearchResults() {
      return getSearchDiff(this.catInfo).isStoreSearch
    },
    abtNoResultPage() {
      return (
        this.listAbtResult?.NoResultPage?.p?.NoResultPage === 'new' &&
        this.ComponentState?.ListTopInfo?.showPostCorrection === 1 
      )
    },
    isSuggestCorrection() {
      return this.listAbtResult?.searchcorrect?.p?.correction_type === 'suggestcorrection'
    },
    skeletonLoadingLockClass({ PageState, isUseSkeletonLoading }) {
      return {
        'skeleton-loading-lock': PageState.skeletonLoading && isUseSkeletonLoading
      }
    },
    filterBarDisPlay() {
      return !!(this.goods.length || (!this.goods.length && this.goodsLoading) || this.PageState.hasFilt || Object.keys(this.dailyDates).length)
    },
    filterConfig({ ComponentState }) {
      return ComponentState?.FilterBar?.filterConfig
    },
    isSearchResultPage() {
      const { type } = this.catInfo
      return type === 'search'
    },
    cateCoupon() {
      const result = { searchCoupon: { couponList: [] }, accountCoupon: { couponList: [] } }
      const { Abt, Data } = this.ComponentState?.ProductList?.cateCoupon
      const { SearchCouponNoti: SearchCouponNotiAbt = '' } = Abt?.SearchCouponNoti?.p || {}
      const [, SearchCouponNotiPos = 0] = SearchCouponNotiAbt.match(/display:(\d+)&max:(\d+)/) || [null, 0, 0]
      result.accountCoupon = Data[SearchCouponNotiPos]
      return result
    },
    policyBannerConfig () {
      const { language = {}, content = {} } = this.policyBanner || {}
      const { isSpaPage, hasHandleFilterBar, skeletonLoading } = this.PageState || {}
      const { isPdSearchPageShow, couponList } = this.cateCoupon?.accountCoupon || {}
      const isShowAccountCoupon = couponList?.length && isPdSearchPageShow && isSpaPage && !hasHandleFilterBar
      return {
        context: {
          lang: this.lang,
          language,
          PUBLIC_CDN: this.PUBLIC_CDN,
          ...this.locals
        },
        // 如果有账号可用券 隐藏福利政策
        content: !isShowAccountCoupon && !skeletonLoading ? content?.content || [] : [],
        sceneData: {
          pageFrom: 'list',
          id: content?.id || '',
          pageType: 'commonListPage',
          sceneName: 'all'
        }
      }
    },
    infiniteScrollDistance() {
      // 命中实验后，深度<6个的时候,触发下一页请求
      return this.searchOptimizeAbt ? 1050 : this.PageState.infiniteScrollDistance
    },
    searchOptimizeAbt() {
      return this.catInfo.type === 'search' && !['store', 'brand'].includes(this.catInfo.search_type) && this.listAbtResult.PageDivideTime?.p?.page_divide_time === 'request_early'
    },
    searchOptimize() { // 搜索页翻页优化
      // 场景：搜索
      // 生效个数：前200个
      // 因为下游会通过viewdGoods过滤数据：page*limit <= 200的情况下，无论page给多少，都会返回最新的商品
      // 其他：跳转商详后返回结果页、点击加车按钮后，再次请求数据，前面请求的用户实际上没看到的数据 需要丢掉
      // 举例：页面上有10个商品，展示了前4个，后点击加车按钮，然后没有滚动页面，此时请求了最新10个商品，这时候会把没有曝光的后面6个商品丢了，所以一共14个商品
      // return true
      return exposeGoodsMaxIdx <= 200 && this.searchOptimizeAbt
    },
  },
  watch: {
    searchOptimize(val) {
      if (!val) { // 搜索页翻页优化深度>200后，移除监听滚动事件
        this.removeScrollEvent()
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') return next()
    const toQuery = to.query
    if (toQuery.backto === 'preSearch' && from.meta.pageType === 'index') {
      // abt控制从首页进来且下次回退需要回到联想页
      const { host, langPath } = gbCommonInfo
      const ici = toQuery.ici || ''
      const keyword = ici.split('`')[2] || '' 
      let url = host + langPath + '/presearch?pageType=all&pagefrom=page_home&search_button=1'
      url += toQuery.show_association === '1' ? '&show_association=1' : ''
      url += '&search_input_default=' + keyword
      window?.history?.pushState(null, 'preSearch', url)
    }
    pageFrom = from.name
    if (toQuery.word_type === '20') {
      searchWordsGlobalAnalysis.set({
        result_type: 20,
        pagefrom: 'AdsSearch'
      })
    }
    next(async (vm) => {
      // 这里处理列表进商详，商详再回来此时goods数据keep-alive的
      // 商详返回时，激活实时反馈弹窗
      const isBackFromDetail = setFeedbackRecInfo({ productListInstance: vm.$refs.ProductList, path: from.path })
      vm.handleBackFromDetail(isBackFromDetail)
      isBackFromDetail && vm.addRecommendProductHandle(isBackFromDetail)
      // 如果 from 有 name, 说明是 SPA 页内跳转, 而非直接进入页面
      // 如果 from 没有 name, 说明是直接进入的页面，走的 SSR, 而不是 SPA 页内跳转
      vm.PageState.isSpaPage = Boolean(from.name)
      to.path !== from.path && vm.switchPath()
    })
  },
  // 切换列表、筛选
  beforeRouteUpdate(to, from, next) {
    next()
    to.path !== from.path && this.switchPath()
    this.hideFeedbackRecPopup()
    // this.hideSearchRec()

    // 如果请求被中断 重置Request.type
    if (isResetRequestType(abortionInstance)) {
      this.Request.type = ''
      this.$refs.FilterBar?.closeCloudTagPanel()
    }

    // 侧边栏跳转问题兼容
    if (this.Request.type === 'refresh') {
      this.Request.fullpath = to.fullPath
    } else {
      setTimeout(() => this.spaFirstIn(to), 50)
    }
    // 锦囊 + 分布式 filterWords变了 更新 tab_page_id
    const { filterWords = '' } = to.query
    if (this.filterWords != filterWords) {
      this.newSrcTabPageId = window.getSaPageInfo?.tab_page_id
      this.filterWords = filterWords
      this.switchPath()
      appEventCenter.$emit('hidePanel')
    }
    // 搜索词变了 更新 tab_page_id
    const matchObj = to.path.match(/pdsearch\/([^\/]+)\/?$/)
    if (matchObj) {
      const searchWords = decodeURIComponent(matchObj?.[1] || '')
      if (this.searchWords != searchWords) {
        this.newSrcTabPageId = window.getSaPageInfo?.tab_page_id
        this.searchWords = searchWords
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    abortRequest()
    this.PageState.pageleave = true
    this.PageState.skeletonLoading = false
    this.updateSearchOptimizeReqFlag(false)

    !this.isOperateInRecPopup && this.hideFeedbackRecPopup()
    // this.hideSearchRec()
    this.$refs.FilterBar?.closeCloudTagPanel()
    // 如果是搜索结果页 需要区分下是否搜索有结果
    const pageName = window?.SaPageInfo?.page_name
    if (pageName === 'page_search') {
      // 虽然是同一页，但是产品表达上有/无结果分别属于不同页面
      const pageFrom = this.goods.length ? 'PageSearchResult' : 'PageSearchNoResult'
      this.$route.meta.pageFrom = pageFrom // 这个字段仅用于区分搜索结果页是否有结果
    } else {
      this.$route.meta.pageFrom = '' // 其余页面不用这个字段
    }
    if (to.name === 'page_goods_detail') {
      next()
    } else {
      appEventCenter.$emit('hidePanel')
      await this.$nextTick()
      // 延迟为了关闭动画完成，避免页面闪烁
      setTimeout(() => next(), 250)
    }
    const isGoSearchPage = /presearch|pdsearch/.test(to.path)
    if (!isGoSearchPage) {
      appEventCenter.$emit('changeRealListHead', false)
      appEventCenter.$emit('resetCurKeyWord', '')
    }

    if (to.name !== 'page_store') {
      this.showFashionStore?.clear()
    }

    // 针对榜单类型，需要从榜单页返回展示榜单反馈弹窗问题修复
    // if(to.name === 'page_rank_products') {
    //   console.log(to?.query?.currentItem, 'to?.query?.currentItemto?.query?.currentItem');
    //   // 更新当前点击的商品ID
    // getFeedbackRecInSession()
    //   setFeedbackRecInSession({ goodsId: to?.query?.currentItem, addBagStatus: false, addWishStatus: false })
    // }
  },
  // vue 2.6+ 新增 api, 只会在 ssr 时调用
  // 它必须返回一个 promise, 这个 promise resolve 后，才会渲染组件
  // 所以可以把 ssr 时的异步数据获取/生成逻辑放在这里
  // 但是要在 created 钩子里注意不要执行同样的逻辑, 建议 csr 的逻辑尽量放在 mounted 钩子里
  async serverPrefetch() {
    const contextForSSR = this.$store.state[storeModuleName].context
    this.resetLocals(contextForSSR)
    return contextForSSR && this.resolveData(contextForSSR)
  },
  created() {
    this.initFashionStoreSet()

    if (this.$isServer) {
      return
    }
    const contextForSSR = this.$store.state[storeModuleName].context
    this.resetLocals(contextForSSR)
    // 服务端与客户端landing接管
    contextForSSR && this.resolveData(contextForSSR)
    this.filterWords = ''
    this.searchWords = this.getSearchWord()
  },
  mounted() {
    addSubscriberPage()
    if (this.$el && this.$el?.tagName) {
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          {
            chunkName: 'goods_detail',
            relType: 'prefetch',
          },
          {
            chunkName: 'middlecontent',
            relType: 'prefetch',
          },
          {
            chunkName: 'bottomcontent',
            relType: 'prefetch',
          },
        ],
        delay: 5000,
        prefetchCallback: ({ status, info }) => {
          console.log('prefetchCallback', status, info)
        },
      })
    }

    appEventCenter && appEventCenter.$on('recommendListPageNum', val => {
      // 刷新推荐的商品总数
      this.recommendListPageNum = val
      this.refreshAnalysisData(true)
    })
    appEventCenter?.$on?.('headerMounted', this.setCatInfo)
    onClickProductCardInGoodsDetail((args) => {
      window.sessionStorage?.setItem('userClickProductLatest', JSON.stringify(args))
    })
  },
  activated() {
    const endMarkPoint = customMarkPoint({ eventName: 'ActivatedSpaRequest' })
    // 更新tab_page_id
    this.newSrcTabPageId = window.getSaPageInfo?.tab_page_id
    this.PageState.pageleave = false
    const contextForSSR = this.$store.state[storeModuleName].context
    // 页面跳转或者离开的情况，重置页面请求code上报的值
    this.searchRequestStatus = ''
    this.searchRecRequestStatus = ''

    if (contextForSSR) { // ssr
      this.Request.fullpath = this.$route.fullPath
      this.Request = {
        ...this.Request,
        query: {
          ...this.Request.query,
          ...this.$route.query,
        }
      }
      delete this.$store.state[storeModuleName].context
    } else if (this.Request.fullpath !== this.$route.fullPath) { // 从非列表页，1. 首次进入 2. 进入非上次的列表页
      // 重置筛选组件
      this?.initFilterBarStyle()
      // this.$refs.FilterBar?.initFilterBarStyle()
      // SPA的情况下须重置顶部栏sticky占位
      appEventCenter?.$emit && appEventCenter.$emit('resetHeaderAppStickyOffset')
      this.initHypernymProductInfo()
      this.spaFirstIn(this.$route)
      this.$nextTick(() => endMarkPoint())
    } else { // 从非列表页，1. 回退到列表页 2. 进入上次的列表页
      const direction = window.appRouteExtData.direction
      // 重进/中继翻页手动 pv & expose
      if (!this.Request.type || this.Request.type === 'nextpage') {
        this.$store.commit('changeSeo', this.cccSeo, { root: true })
        this.refreshAnalysisData()
      }
      if (this.Request.type) {
        if (direction !== -1) {
          this.PageState.skeletonLoading = true
        }
        if (pageFrom === 'page_goods_detail' && this.searchOptimize && !this.PageState.searchOptimizeReqFlag) {
          this.fetchNewestData()
        } else {
          this.fetchData({
            requestType: this.Request.type,
            from: 'activated'
          })
        }
      } else {
        if (pageFrom === 'page_goods_detail') {
          this.fetchNewestData()
        }
      }
      this.addScrollExpand()
      // 获取一键购资格
      checkOneClickPayQualification().then(qualificationInfo => {
        if (!qualificationInfo?.billno || !qualificationInfo?.support_one_click_pay) {
          this.clearBannerTagState()
        }
      })
    }
    this.setCatInfo()  // 页面首次加载或者 keep-alive情况下，从其它页面返回的情况下也要更新
  },
  deactivated() {
    if (this.PageState.searchPageLoading) {
      this.closePageLoading()
      this.updateSearchOptimizeReqFlag(false)
    }
  },
  beforeDestroy() {
    appEventCenter?.$off?.('headerMounted', this.setCatInfo)
    offClickProductCardInGoodsDetail()
    this.removeScrollEvent()
  },
  methods: {
    ...mapMutations(['changeRootSLoadingStatus']),
    ...mapMutations(storeModuleName, ['resetLocals', 'resetState', 'setPageState', 'setBannerTagState', 'setGoodsCheckoutNum', 'deleteUnexposedGoods', 'updateSearchOptimizeReqFlag']),
    ...mapActions(storeModuleName, ['dealingNewData', 'initState', 'fetchComponentAsyncData']),
    // hack code 处理上位词和搜索结果列表同时存在，上位词加车不生效问题
    dropCart(target) {
      this.$refs?.ProductList?.injectsDropCart(target)
    },
    // scrollAction,
    notifySearchExposure(){
      const { query, path } = this.$route
      if (query?.search_type == 'store' && /\/pdsearch\/.+\//.test(path)) {
        // 搜索结果页曝光,用于通知店铺搜索页头部外露曝光
        appEventCenter.$emit('pdsearchExposure')
      }
    },
    // S -- requesting
    spaFirstIn({ fullPath, query }) {
      this.Request.fullpath = fullPath
      this.Request.query = {
        ...query,
        limit: 10,
        page: 1,
      }
      this.fetchData({
        requestType: 'firstload', 
        isUpdateRoute: false, 
        firstIn: true,
        from: 'spaFirstIn'
      })
    },
    setHeaderLoaderStatus(value) {
      this.headerLoaderStatus = value
    },
    loadMore() {
      if (this.onLineListened) {
        return
      }
      this.Request.query.page += 1
      this.fetchData({
        requestType: 'nextpage',
        from: 'loadMore'
      })
      this.offlineHanler()
    },
    // 断网处理
    offlineHanler () {
      if (typeof window !== 'undefined' && !navigator.onLine) {
        this.onLineListened = true
        const handler = () => {
          this.onLineListened = false
          this.fetchData({
            requestType: 'nextpage',
            from: ' offline'
          })
          window.removeEventListener('online', handler)
        }
        window.addEventListener('online', handler)
      }
    },
    updateQuery({ params, rightFilterFalg }) {
      this.rightFilterFalg = !!rightFilterFalg // 右侧筛选框标识
      this.Request = {
        ...this.Request,
        query: {
          ...this.Request.query,
          ...params,
          limit: 10,
          page: 1,
        }
      }
      this.cancelScrollExpand()
      this.updateQueryParams(params)
      // 筛选 排序即隐藏搜索优惠券
      this.setPageState({
        hasHandleFilterBar: true,
      })
      abortRequest()
      this.fetchData({
        requestType: 'refresh',
        isUpdateRoute: true,
        from: 'updateQuery'
      })
    },
    async fetchData({
      requestType, 
      isUpdateRoute, 
      firstIn,
      from,
    }) {
      if (requestType === 'firstload') {
        markPoint({ eventName: 'waitPageData', measureFrom: 'routeChangeEnd' })
        markPoint({ eventName: 'PageWaitInit', measureFrom: 'RouterEndTrigger' })
      }

      if (requestType !== 'nextpage') {
        exposeGoodsMaxIdx = 0
        this.updateSearchOptimizeReqFlag(false)
      }
      this.Request.type = requestType
      this.invokeRefresh()

      // 第一次到第 3 页的时候, 把 page 重置为 2, 因为前两页一共只加载了 20 个商品, 所以如果 limit 改为 20 后, 页码要减 1
      const isFirstPageThree = this.Request.query.page === 3 && this.Request.query.limit === 10
      if (isFirstPageThree) {
        this.Request.query.page = 2
        this.Request.query.limit = 20
      } else if (from === 'searchOptimize') {
        // this.Request.query.page = 1 // 前200个商品都是最新的，和page无关
        this.Request.query.goodsMaxViewedIdx = exposeGoodsMaxIdx ? exposeGoodsMaxIdx + 1 : 0 // 用于切割viewedGoods，未露出的goods可能会被删除，因此不能直接使用goods去计算viewdGoods
      } else {
        this.Request.query.goodsMaxViewedIdx = 0
      }

      this.PageState.landingLock = requestType !== 'nextpage'
      this.PageState.infiniteScrollLock = true

      // 主动调用此函数暂不需要 context 对象, 但是为了兼容 TMG 调用此函数时传入的 context 对象, 所以这里给个占位的空对象
      try {
        const cacheData = getProductListApiCache(this.Request, this.newSrcTabPageId, this.$route)

        // 没有预取请求结果
        const isCache = cacheData && Object.keys(cacheData).length > 0

        // 是否直达词
        const isRedirectUrl = cacheData?.searchKeywords?.redirectUrl && !cacheData?.goods?.length
        
        if ((!isCache || isRedirectUrl) && firstIn) {
          this.handlePageLoadSearch()
        } else {
          this.PageState.searchPageLoading = false
        }
        this.setCatInfo() // 涉及到category筛选的时候，需要去更新header组件里面的catInfo，从而触发底纹词请求【这个地方最好是加个判断，是触发筛选的情况下的请求才应该触发】
        const newData = await requestProductListApi(this.Request, this.newSrcTabPageId, this.$route)
        if (requestType === 'firstload') {
          markPoint({ eventName: 'ApiResponseGoods', measureTo: 'XHRLoadGoods' })
          markPoint({ eventName: 'pageDataReady', measureFrom: 'waitPageData' })
          handleTrackFetchTime(newData?._traceId)
        } else if (from === 'searchOptimize' && newData?.goods?.length) {
          // 1. 删除未曝光的商品
          // 2. resolveData newData会将新数据放在goods
          // 3. 临界情况1: 加车按钮触发获取最新数据，此时商品未拿到，用户又去下滑了，此时exposeGoodsMaxIdx 还未更新，因此不能删除goods
          // 4. 临界情况2：遍历newData?.goods 和goods最后6个，如果有相同数据，就把newData里的删了
          if (exposeGoodsMaxIdx && !this.scrolling) {
            this.deleteUnexposedGoods(exposeGoodsMaxIdx)
          }
          let newGoods = []
          const lastSixDataGoodsIds = this.goods.slice(-20).map(i => i.goods_id)
          newData.goods.forEach(i => {
            if (!lastSixDataGoodsIds.includes(i.goods_id)) {
              newGoods.push(i)
            }
          })
          newData.goods = newGoods // 这里过滤后可能为空，需要加标记
          newData.searchOptimize = true // 加标记，否则就会nomoreData，在mutation处理了
        }

        if (!newData.sheinClubInfo) {
          newData.sheinClubInfo = this.sheinClubInfo
        }

        if (!newData.language) {
          newData.language = this.language
        }

        this.initAdapter(newData).adpatSearchResultInterfaceCode()
        const { searchResultInterfaceCode } = newData || {}
        this.searchRequestStatus = searchResultInterfaceCode || '4_1001', // 拿不到的情况下认为是请求中间层异常

        this.refreshAnalysisData(true)

        // 无返回重置状态
        if (!newData || Object.keys(newData).length === 0) {
          this.closePageLoading()
          if (from === 'searchOptimize'){
            // 被searchOptimize手动终止的其他请求，不更新SearchOptimizeReqFlag
            this.updateSearchOptimizeReqFlag(false)
          }
          return
        }
        // 新增数据缓存后，replace可能在newData后导致resolveData异常
        isUpdateRoute && await this.updateRoute()
        if (requestType === 'firstload') {
          markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
        }
        this.resolveData(newData)
        if (requestType === 'firstload') {
          markPoint({ eventName: 'PageResolveData', measureFrom: 'setPageData' })
        }
        // 客户端首次重算首屏的请求关闭 lcp 开关
        if (newData.cat_info?.requestType !== 'nextpage') {
          this.PageState.lcp = false
        }
        this.$nextTick(() => {
          requestType === 'firstload' && markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })
        })
      } catch (error) {
        this.closePageLoading()
        this.updateSearchOptimizeReqFlag(false)
        console.error(error)
      }
    },

    invokeRefresh() {
      if (this.Request.type === 'nextpage') return
      this.$refs.ProductList?.clearUseGooIds() // 页面发生刷新行为，清除已经使用过的searchWordRecMixin.js中定义
    },

    initHypernymProductInfo() {
      this.hypernymProductInfo = {
        list: [],
        page: 1,
        limit: 20,
        sum: null, // null:默认 number: 上位词请求回来后的总数
        loading: false,
        noMoreData: false,
      }
    },

    handleHypernymFetch({ sum }) {
      if (this.hypernymProductInfo.sum === 0 || this.catInfo.type !== 'search' || ['store', 'brand'].includes(this.catInfo.search_type)) {
        // 这里表示firstload的时候用searchword请求结果为0，因此不再重复请求
        // 非搜索结果页面不去获取上位词商品
        this.$set(this.hypernymProductInfo, 'noMoreData', true)
        return
      }
      const { page, limit } = this.Request.query
      const lastPaged = parseInt(page) >= Math.ceil(sum / limit)
      if (!lastPaged || !this.hypernymProductInfo.keywords || sum > 200) {
        this.$set(this.hypernymProductInfo, 'noMoreData', true)
        return
      }
      this.fetchHypernyData()
    },

    // S -- resolving
    async resolveData(newData) {
      // bff字段适配
      this.initAdapter(newData).adaptData()

      const requestType = newData.cat_info?.requestType
      if (this.doSearchPreprocess(newData)) return
      this.closePageLoading()
      if (requestType === 'firstload') {
        this.hypernymProductInfo.keywords = newData.searchKeywords?.origin_words
        const PageStateArgs = {
          exposeCode: ListAnalytics.containerExposeCode,
          isSpaPage: this.PageState.isSpaPage,
          firstLoadTimes: this.PageState.firstLoadTimes,
        }
        this.resetState({
          payload: newData,
          PageStateArgs,
        })
      }
      this.Request.query.page = +newData.cat_info?.page
      this.Request.query.limit = +newData.cat_info?.limit
      this.$route?.query && (this.$route.query.limit = +newData.cat_info?.limit)  // 在activated中会把route的query混入到Request的query中，会导致上面的limit设置失败，所以这个地方需要更新一下route中的limit信息
      this.dealingNewData(newData)
      if (typeof newData.getHypernymProductSum === 'number') {
        this.hypernymProductInfo.sum = newData.getHypernymProductSum
      }
      this.initState(newData)
      this.Request.type = ''
      if (typeof window === 'undefined') return

      const tasks = this.fetchComponentAsyncData(requestType)

      if (requestType === 'firstload') {
        this.$store.commit('changeSeo', this.cccSeo, { root: true })
        this.$refs.brandBanner?.resetFalconlazy()
        this.$refs.searchCCCCard?.resetFalconlazy()
        this.$refs.bgBanner?.resetFalconlazy()
        this.$route.meta.type = newData.cat_info?.type
        // 如果是 SPA 跳转, 并且 ListAnalytics 有值, 需要将其重置为 null
        // 避免后面上报 expose_goods_list 出现时序问题
        this.ListAnalytics = null

        // 业务监控日志 - 页面打开成功次数累加
        metricPageSuccess({
          page: newData.cat_info?.pageName,
          status: newData?.goods?.length > 0 ? '1' : '0',
        })
      }

      if (requestType !== 'nextpage') {
        if (window.requestIdleCallback) {
          window.requestIdleCallback(
            () => {
              this.refreshAnalysisData()
              this.notifySearchExposure() // 等页面埋点信息初始化发送通知头部店铺外露埋点
            },
            { timeout: 2000 }
          )
        } else {
          setTimeout(() => {
            this.refreshAnalysisData()
          }, 2000)
        }

        this?.initFilterBarStyle({ isFilter: requestType !== 'firstload' })
      }

      $('.j-skeleton-container').remove()
      this.goodsLoading = true
      appEventCenter.$once('goodsLoadedOnPageList', async () => {
        this.getBuyBoxPrices(newData.goods)
        this.goodsLoading = false
        this.PageState.infiniteScrollLock = false
        await this.$nextTick()
        // === dom render completed ===
        if (requestType === 'firstload') {
          this.setSilInMeta()
          this.setCatInfo()  // 这个地方执行是因为当页面首次进入的时候，activity里面执行的那次this.$refs.ListTopInfo会拿不到值
        }
        await tasks

        // 设置商品购物车加车数量
        await this.checkoutGoodsInfo()

        await this.$nextTick()
        // === async component render completed ===
        const subscribeFn = () => {
          const compsCode = this.$refs.ProductList?.setAnalysisData(requestType)
          this.initListExpose({
            exposeIds: compsCode,
          })
        }
        if (this.ListAnalytics) {
          subscribeFn()
        } else {
          this.$once('ListAnalyticsReady', () => {
            subscribeFn()
          })
        }
        if (this.Request.type) return
        
        if (this.goods.length < 9 && !this.noMoreSearchData) {
          await this.$nextTick()
          this.Request.query.limit = +newData.cat_info?.limit
          if (this.PageState.pageleave) {
            this.Request.query.page += 1
            this.Request.type = 'nextpage'
          } else {
            this.loadMore()
            return // loadMore不处理handleHypernymFetch
          }
        } else {
          this.PageState.landingLock = false
        }
        this.handleHypernymFetch({ sum: newData.sum }) // 处理上位词： 这里需要兼容loadMore方法异步回来后触发。

      })
    },
    onSubscribeExpose(event) {
      if (this.ListAnalytics) {
        this.ListAnalytics.subscribeExpose(event)
      } else {
        this.$once('ListAnalyticsReady', () => {
          this.ListAnalytics.subscribeExpose(event)
        })
      }
    },
    // S -- functional
    // 搜索落地重定向检测 & 记录历史记录
    doSearchPreprocess({ cat_info, searchKeywords }) {
      if (typeof window === 'undefined') {
        return false
      }

      const { store_code: storeCode, intent_channel_id, search_type: searchType } = this.$route.query
      const localCacheWordKey = searchType === 'store' ? storeCode : intent_channel_id

      if (searchKeywords?.redirectUrl && !['store', 'brand'].includes(searchType)) {
        // search SPA重定向
        appEventCenter.$emit('searchRedirect', {
          searchWords: searchKeywords.keywords,
          pageType: searchType,
          localCacheWordKey,
          storeCode,
        })
        searchWordsGlobalAnalysis.refreshRedir(true)

        const url = searchKeywords.redirectUrl //直达词在这跳的，
        // 直达词 走跳转
        if (/^\/\//.test(url)) {
          location.href = url
        } else {
          this.Request.type = ''
          spaJump({
            routerVm: this.$router,
            type: 'replace',
            url,
          })
        }

        return true
      } else if (cat_info?.requestType === 'firstload' && searchKeywords?.keywords) {
        // 搜索落地刷新历史记录
        appEventCenter.$emit(
          'searchPageFirstLoad',
          Object.assign(searchKeywords, {
            pageType: searchType,
            localCacheWordKey,
            storeCode,
          })
        )
        return false
      }
    },
    // 储存 Sil 信息到路由元
    setSilInMeta() {
      if (this.catInfo.type === 'search') {
        // 商详返回时，激活实时反馈弹窗(页面被缓存，确保此时的页面mounted完成, 针对多级路由跳回，goods数据被销毁的情况)，参见需求FR-14861
        const isBackFromDetail = setFeedbackRecInfo({ productListInstance: this.$refs.ProductList, path: pageFrom })

        this.handleBackFromDetail(isBackFromDetail)

        const { sil_type, list_cat_id, list_cat_name } = this.$route.query
        sil_type && list_cat_id && list_cat_name
          ? (this.$route.meta.silInfo = { sil_type, list_cat_id, list_cat_name })
          : delete this.$route.meta.silInfo
      } else {
        const sil_type = this.catInfo.type
        const list_cat_id = this.catInfo.entity_id || this.catInfo.select_id
        const list_cat_name = this.$refs.ListTopInfo?.title
        sil_type && list_cat_id && list_cat_name
          ? (this.$route.meta.silInfo = { sil_type, list_cat_id, list_cat_name })
          : delete this.$route.meta.silInfo
      }

    },

    setCatInfo(ins) {
      // 非entity和selection不处理， 去到搜索结果页返回到列表页的时候，会出现this.$route?.meta?.type为entity，但是this.catInfo?.type为search的情况
      if (!['entity', 'selection'].includes(this.$route?.meta?.type) || !['entity', 'selection'].includes(this.catInfo?.type)) return
      // 针对category筛选过，然后去到搜索结果页，返回的情况下，保留之前筛选过的category
      const { child_cat_id, pic_nav_type, pic_nav_id, nav_query_id, nav_query_type, nav_query_name } = this.Request?.query || {}
      const isBackFromDetail = setFeedbackRecInfo({ productListInstance: this.$refs.ProductList, path: pageFrom })
      this.catInfo.isBackFromDetail = isBackFromDetail
      // 针对category筛选过，更新筛选的子类目的childCatId  pic_nav_type == 1 表示是图文筛选是属于类目, child_cat_id存在说明是在做category筛选
      if (child_cat_id) {
        this.catInfo.childCatId = child_cat_id
      } else if (pic_nav_type == 1 && pic_nav_id) {  // 点击图文筛选不跳转页面的情况
        this.catInfo.childCatId = pic_nav_id
      } else if (nav_query_type == 1 && nav_query_id) {  // 点击图文筛选会跳转到新页面的情况
        this.catInfo.childCatId = nav_query_id
      } else {
        this.catInfo.childCatId = ''
      }
      // 更新cateName和当前页面的catId
      const list_cat_name = this.$refs.ListTopInfo?.title
      if (nav_query_type == 1 && nav_query_id && nav_query_name) {
        this.catInfo.cat_name = nav_query_name
      } else {
        this.catInfo.cat_name = list_cat_name
      }
      // 真实列表场景，会出现去到结果页再回到真实列表场景的时候，this.catInfo?.cate_ids会变掉，🤮了
      // this.catInfo?.cate_ids && (this.catInfo.cat_id = this.catInfo.cate_ids)
      if (this.$route?.meta?.type === 'entity' && this.catInfo?.entity_id) {
        this.catInfo.cat_id = this.catInfo?.entity_id
      }
      if (ins) {
        ins?.setCatInfo(this.catInfo)
      } else {
        appEventCenter?.$emit?.('resetCatInfo', this.catInfo)
      }
    },

    // 列表内搜索无结果全局搜入口
    searchInAll() {
      delete this.$route.meta.silInfo
      const remove = {
        sil_type: undefined,
        list_cat_id: undefined,
        list_cat_name: undefined,
      }
      this.updateQuery({ params: remove })
      // 先测试 refresh 有词更新问题再换 firstload
      // const query = {
      //   ...this.$route.query,
      //   ...remove,
      // }
      // this.$router.replace({ query })
    },
    // 筛选无结果重筛入口
    reselect () {
      this.openSideFilter()
    },
    // S -- style
    updateRoute () {
      return new Promise(resolve => {
        const data = { ...this.Request.query }
        Object.keys(data).forEach((key) => {
          if ((key === 'min_price' || key === 'max_price') && data[key] !== '') return
          if (key === 'page' || !data[key]) return delete data[key]
          if (key === 'pic_nav_pos') delete data[key]
          if (key === 'price_input') delete data[key]
        })

        const queryArr = Object.keys(data)
        if (!queryArr.length) return this.$router.replace(this.$route.path, resolve, resolve)

        // attr_values前置展示
        if (data.attr_values) {
          queryArr.splice(queryArr.indexOf('attr_values'), 1)
          queryArr.unshift('attr_values')
        }

        // 拼接替换url
        const showParams = queryArr.map((key) => `${key}=${encodeURIComponent(data[key])}`).join('&')

        // router无法检测 history.replaceState, 侧边栏会视为同链接从而进不来
        this.$router.replace(`${this.$route.path}?${showParams}`, resolve, resolve)
      })
    },
    // S -- analysis
    /**
     *  @param noSend :true 表示不发送埋点，只更新
     */
    refreshAnalysisData(noSend = false) {
      window.requestIdleCallback(() => {
        if (!this.ListAnalytics) this.ListAnalytics = new ListAnalytics(this.locals)

        this.ComponentState.ProductList.analysisData = this.ListAnalytics.resetPageData({
          listAbtResult: this.listAbtResult,
          catInfo: this.catInfo,
          goods: this.goods,
          searchKeywords: this.searchKeywords,
          tracking: this.tracking,
          request_ext: this.request_ext,
          hypernymRequestExt: this.hypernymProductInfo?.request_ext || {},
          sum: this.sum,
          currentCat: this.currentCat,
          parentCats: this.parentCats,
          goodsCrowId: this.goodsCrowId,
          fhContext: this.fhContext,
          googleContext: this.googleContext,
          listFilterAbt: this.listFilterAbt,
          extraData: {
            priceInput: this.Request.query.price_input,
            sortType: this.ComponentState?.FilterBar?.filterConfig?.sortType,
            recResultCount: this.catInfo.type !== 'search' || ['store', 'brand'].includes(this.catInfo.search_type)  ? 'hidden' : this.hypernymProductInfo.sum,
            recommendListPageNum: this.recommendListPageNum,
          },
          searchTraceId: this.PageState.searchTraceId,
          searchRequestStatus: this.searchRequestStatus,
          searchRecRequestStatus: this.searchRecRequestStatus
        }, noSend)
        window.JOURNEY_BRANCH.pageView(this)
        const isBack = window.appRouteExtData.direction === -1
        if (this.ListAnalytics && this.ListAnalytics?.resetExposeStatus && !noSend) {
          this.$nextTick(() => this.ListAnalytics?.resetExposeStatus(isBack, this.ComponentState))
          this.$emit('ListAnalyticsReady')
        }
      })
    },
    /** 图片裁切 */
    cutImg(imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.locals || {}
      const {
        deviceData = '',
        isSupportWeb = '',
        isSupprotCut = false,
        sceneMap = {},
      } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    // Apollo返回是否支持补图
    getIsSupportCropImage() {
      const { RESOURCE_SDK = {} } = this.locals || {}
      const { isSupportCropImage = false } = RESOURCE_SDK || {}
      return isSupportCropImage
    },
    async handleOperateInRecPopup() {
      this.isOperateInRecPopup = true

      await this.$nextTick()

      this.isOperateInRecPopup = false // 状态重置
    },
    hideFeedbackRecPopup() {
      this.$refs.ProductList?.hideFeedbackRecPopup()
    },
    // 关闭搜索推荐浮层
    // hideSearchRec() {
    // this.$refs.ProductList?.hideSearchRecMethod() // minx中的方法
    // this.$refs.ProductList?.clearUseGooIds() // 页面发生刷新行为，清除已经使用过的goodId
    // },
    getSearchWord() {
      const matchObj = this.$route.path.match(/pdsearch\/([^\/]+)\/?$/)
      if (!matchObj) return ''
      return decodeURIComponent(matchObj?.[1] || '')
    },
    // 上位词商品相关
    async fetchHypernyData(requestType) {
      const { limit, keywords, page, list } = this.hypernymProductInfo
      let hPKeywords = keywords
      if (!hPKeywords) return
      this.hypernymProductInfo.loading = true
      try {
        const { goods = [], hypernymProduct = {}, request_ext, searchResultInterfaceCode } = await requestProductListApi({
          type: 'nextpage',
          query: {
            isHypernymGoods: true,
            page,
            limit,
            hPKeywords,         
            market_code: getQueryString({ key: 'market_code' })
          },
        }, this.newSrcTabPageId, undefined, true)
        this.searchRecRequestStatus = searchResultInterfaceCode || '4_1001' // 拿不到的情况下认为是请求中间层异常
        this.refreshAnalysisData(true)

        const newGoods = dealProductsPretreatInfo({
          products: goods,
          itemConfig: this.ComponentState.ProductList,
          promotionInfoFromServer: this.Results.promotionInfoFromServer,
          language: this.language,
        })
        hypernymProduct.sum = +hypernymProduct.sum
        Object.assign(this.hypernymProductInfo, hypernymProduct)

        this.hypernymProductInfo.request_ext = request_ext

        this.$set(this.hypernymProductInfo, 'list', [...list, ...newGoods])

        hypernymProduct.page == 1 && this.refreshAnalysisData(true)

        await this.$nextTick()

        if (requestType === 'loadMore') {
          this.initHypernymListExpose()
        }
      } finally {
        this.hypernymProductInfo.loading = false
      }
    },
    loadMoreHypernymGoods() {
      this.hypernymProductInfo.page = parseInt(this.hypernymProductInfo.page) + 1
      this.fetchHypernyData('loadMore')
    },
    initHypernymListExpose() {
      this.$refs.HypernymProductWrap?.setAnalysisData()
      const { config } = this.ComponentState.ProductList || {}
      this.initListExpose({
        exposeIds: config.itemDAEventExposeId,
      })
    },
    initListExpose({ exposeIds }) {
      const { config } = this.ComponentState.ProductList
      this.ListAnalytics.subscribeListExpose(exposeIds, ({ targets, daId }) => {

        // 悬浮购物车下单引导
        targets.forEach(el => {
          const itemIndex = el.getAttribute('data-index')
          exposeGoodsMaxIdx = this.searchOptimize ? exposeGoodsMaxIdx > itemIndex ? exposeGoodsMaxIdx : Number(itemIndex) : 0
          this.$refs.ProductList?.listExposeTriggerCartBagTip(itemIndex)
        })

        if (daId === config.itemDAEventExposeId) {
          const exposeGoodIds = []
          targets.forEach(el => {
            // 曝光分布式筛选
            const searchFilter = el.getAttribute('data-search-filter')
            searchFilter && exposeSearchFilterAna(searchFilter, el)
            const dataId = el.getAttribute('data-id')
            if (dataId) {
              exposeGoodIds.push(dataId)
            }
          })
          // 一些策略 让商详更快上屏 - S
          handleGoodsDetailTrigger({ goods: this.goods, exposeGoodIds })
          if (!this.hasPreFetchedGoodsDetail) {
            this.hasPreFetchedGoodsDetail = true
            const loadComponent = prefetchResource.importAsyncComponent({
              chunkName: 'goods_detail', // 跟webpackChunkName保持一致
              componentFactory: () => import( /* webpackChunkName: 'goods_detail' */ '../goods_detail/container'),
            })
            loadComponent()
          }
          // 一些策略 让商详更快上屏 - E   
        }
      })
    },
    switchPath () {
      if (this.isNewFilterBar) {
        this.switchListPage()
        return
      }
      this.$refs.FilterBar && this.$refs.FilterBar.switchPath()
      this.$refs.refPolicyBanner && this.$refs.refPolicyBanner.reset()
    },
    // 更新政策条吸顶top
    async updatePolicyBannerScrollTop (isLoop = false) { 
      if (!this.policyBannerConfig?.content?.length) return
      this.$refs.refPolicyBanner?.updateScrollTop?.(isLoop)
    },
    handleSaveCurrAttr(e, type = 'attr') {
      this.PageState.staticAttrSetId = e
      this.PageState.staticFilterType = type
    },
    handleDoFilt({ attr_id, query }) { 
      this.PageState.staticAttrSetId = attr_id
      this.PageState.staticFilterType = 'attr'
      this.updateQuery({ params: query, isFilter: true })
    },
    handleClickGoods(item) {
      this.ComponentState.SearchFeedback && (this.ComponentState.SearchFeedback.lock = true)
      this.curClickItem = item // 存住当前点击的商品(不需要再data中声明)，为了解决二次请求的情况
      // 点击商品，提前请求点后推 动态插坑
      this.insertRecommendGoodFun(item, this.Results?.sheinClubInfo?.isPaid) // 请求泛列表的点后推
    },
    clickStartAddQuick(item) {
      this.curClickItem = item // 存住当前点击的商品(不需要再data中声明)，为了解决二次请求的情况
      // 点击商品，提前请求点后推 动态插坑
      this.insertRecommendGoodFun(item, this.Results?.sheinClubInfo?.isPaid) // 请求泛列表的点后推
    },
    handlePageLoadSearch() {
      // 当是搜索结果页，直达词判断前需要显示全屏loading，后显示骨架屏
      if (this.$route.path.includes('/pdsearch/') && !this.$route.query.store_code) {
        this.PageState.searchPageLoading = true
        this.showPageLoading()
      } else {
        this.PageState.searchPageLoading = false
        this.PageState.skeletonLoading = true
      }
    },
    showPageLoading() {
      this.changeRootSLoadingStatus({
        show: true,
        type: 'newpage',
        containerCoverStyle: pageLoadingStyle,
        curPageMaskTopBoundSelector: '',
        curPageScrollFix: true
      })
    },
    closePageLoading() {
      this.PageState.searchPageLoading = false
      this.PageState.skeletonLoading = false
      this.changeRootSLoadingStatus({
        type: '',
        show: false,
        containerCoverStyle: {},
        curPageMaskTopBoundSelector: '.j-common-logo-header',
        curPageScrollFix: true, // 当前加载 禁止其他滚动
        maskTransparent: false, // 蒙层透明 默认不透明
      })
    },
    handleOneClickPayComplete() {
      checkOneClickPayQualification().then(qualificationInfo => {
        if (!qualificationInfo?.billno || !qualificationInfo?.support_one_click_pay) {
          this.clearBannerTagState()
        }
      })
    },
    clearBannerTagState() {
      this.setBannerTagState({
        showTag: 0,
      })
    },
    handleListCartAbt() {
      const { ListAddedCart, SearchAddedCart } = this.listAbtResult
      const abtValue = this.isSearchResultPage ? (SearchAddedCart?.p?.SearchAddedCart || '') : (ListAddedCart?.p?.ListAddedCart || '')
      const added = abtValue.includes('Added') ? 'Added' : ''
      const checkout = abtValue.includes('CheckOut') ? 'CheckOut' : ''

      // mock abt
      // return ['Added', 'CheckOut']
      return [added, checkout].filter(Boolean)
    },
    // 检查商品是否已经加车
    async checkoutGoodsInfo() {
      const addedAbt = this.handleListCartAbt().includes('Added') 
      if (!addedAbt) return

      const skcList = this.goods.map(item => item.goods_sn)
      const checkoutedInfo = await getCartNumBySkc(skcList) || {}

      this.setGoodsCheckoutNum({ checkoutedInfo, showCheckoutNum: addedAbt })
    },
    // 快加车成功检查商品是否已经加车
    async handleQuickAddSuccess({ goods_sn, index, addSuccess }) {
      const addedAbt = this.handleListCartAbt().includes('Added') 
      const checkoutAbt = this.handleListCartAbt().includes('CheckOut') 
      if (!addedAbt && !checkoutAbt) return

      const checkoutedInfo = await getCartNumBySkc([ goods_sn ], { updateCartData: addSuccess }) || {}
      if (checkoutedInfo[goods_sn]) {
        const showCheckout = checkoutAbt && this.cartInfo?.isFreeShipping
        this.setGoodsCheckoutNum({ checkoutedInfo, showCheckout, showCheckoutNum: addedAbt })
        // 埋点
        this.$refs?.ProductList?.setAddCartType(index, checkoutAbt ? 'add_cart_type_checkout' : addedAbt ? 'add_cart_type_added' : 'add_cart_type_add')
      }
    },
    // 从商品详情页返回检查商品是否已经加车
    async handleBackFromDetail(isBackFromDetail) {
      const addedAbt = this.handleListCartAbt().includes('Added') 
      const checkoutAbt = this.handleListCartAbt().includes('CheckOut') 
      if (!addedAbt && !checkoutAbt) return

      if (isBackFromDetail) {
        try {
          const detailAddCartInfo = getFeedbackRecInSession()
          if (detailAddCartInfo.goodsId) {
            let activedGoodIndex = 0
            const hitGoods = this.goods.map((item, index) => {
              if (String(item.goods_id) === String(detailAddCartInfo.goodsId)) {
                activedGoodIndex = index
                return item
              }
            }).filter(Boolean)

            const skcList = hitGoods.map(item => item.goods_sn)
            const checkoutedInfo = await getCartNumBySkc(skcList) || {}

            const addedStatus = hitGoods?.[0]?.checkoutNum || (!!detailAddCartInfo.addBagStatus)
            const showCheckout = checkoutAbt && this.cartInfo?.isFreeShipping && addedStatus
            this.setGoodsCheckoutNum({ checkoutedInfo, showCheckout: !!showCheckout, showCheckoutNum: addedAbt })

            if (skcList[0] && checkoutedInfo[skcList[0]]) {
              // 埋点
              this.$refs?.ProductList?.setAddCartType(activedGoodIndex, checkoutAbt ? 'add_cart_type_checkout' : addedAbt ? 'add_cart_type_added' : 'add_cart_type_add')
            }
          }
        } catch (error) {
          console.log(error)
        }
      }

    },
    initFashionStoreSet() {
      // 泛列表是否展示过时尚店铺内容体
      this.showFashionStore = new Set()
    },
    fetchNewestData() { // 搜索结果页优化：点击加车后和从商详返回，去请求最新数据
      const { searchOptimize, PageState } = this
      if (!searchOptimize || PageState.searchOptimizeReqFlag) {
        return 
      }
      this.bindScrollEvent()
      // 1. 请求下一页数据
      abortRequest()
      this.updateSearchOptimizeReqFlag(true)

      this.fetchData({
        requestType: 'nextpage',
        from: 'searchOptimize'
      })
    },
    handleScroll: throttle(function () {
      this.scrolling = true
      // 处理停止滚动
      if (scrollTimeout !== null) {
        clearTimeout(scrollTimeout)
      }
      
      scrollTimeout = setTimeout(() => {
        this.scrolling = false
      }, 500)
    }, 10),
    bindScrollEvent () {
      document.addEventListener('scroll', this.handleScroll)
    },
    removeScrollEvent () {
      document.removeEventListener('scroll', this.handleScroll)
    },
    initAdapter(newData) {
      return new BffAdapter({ 
        data: newData, 
        requestType: this.Request.type, 
        query: this.Request.query, 
        route: this.$route 
      })
    }
  },
}
</script>

<style lang="less">
.product-list-v2 {
  position: relative;
  background: #fff;
  &_new {
    background: #f6f6f6;
  }
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
  }
  &.skeleton-loading-lock {
    width: 100%;
    height: 20.504rem;
    overflow: hidden;
    .list-skeleton-container {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
    /** ios absolute 闪屏问题 start */
    .product-list-filter {
      visibility: hidden;
      opacity: 0;
    }
    .product-list-v2__topinfo {
      visibility: hidden;
    }
    .product-list-v2__list {
      visibility: hidden;
    }
    /** ios absolute 闪屏问题 end */
  }
}
</style>
<style lang="less" scoped>
  .no_more {
    padding: 0.64rem 0.32rem;
    text-align: center;
    font-size: 0.32rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.37rem;
    line-height: normal;
    color: #767676;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    span{
      display: inline-block;
      vertical-align: middle;
      margin: 0 6px;
    }
    i.wrap {
      display: inline-block;
      width: 24px;
      height: 1px;
      background-color: #767676;
      vertical-align: middle;
    }
  }
  .top_gap{
    width: 100%;
    height: 10px;
    background-color: #F2F2F2;
  }
</style>
