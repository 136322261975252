let instance = null
let importComFn = null
let queue = []
// similar_product_modal.vue是一个全局组件，已在全局的app.vue中注册，无需在各组件内单独注册，需要保证全局只有一个弹窗实例，避免多个弹窗实例滥用导致未知bug
let isGbMounted = false
// 因为弹窗里面放的是推荐组件
// 推荐组件 ==> 商品组件 ==> vuex ==> 需要在根组件app中注册
// 所以当前组件不能单独作为一个vue实例去使用
export const install = (fn) => {
  if (!fn) return
  importComFn = fn
}

export const instanceMounted = (similarInstanceRef) => {
  if (isGbMounted) instance = similarInstanceRef
  if (queue.length) {
    queue.forEach(i => i())
    // 清空队列
    queue.length = 0
  }
}

export default {
  async showModal(params, extendParams) {
    if (instance) {
      return instance.showModal(params, extendParams)
    }
    if (typeof importComFn == 'function') {
      isGbMounted = true
      await importComFn()
      // 1. 队列函数
      queue.push(() => {
        instance.showModal(params, extendParams)
      })
      // 2. 执行函数时机
      if (instance) {
        queue.forEach(i => i())
        // 清空队列
        queue.length = 0
      }
    } else {
      console.error('lack import similar-product-vue components fn')
    }
  }
}
